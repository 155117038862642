import React from 'react'
import "../style/common.css"

const BreakComponent = () => {
    return (
        <div className='break-components'>
            <hr></hr>
        </div>
    )
}

export default BreakComponent