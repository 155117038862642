import React from 'react'
import "../style/common.css";

const Footer = () => {
    return (
        <div className='footer-main'>
            <p className='footer-txt2'>Thank You for your valuable time</p>
            <p className='footer-txt'>@copywrite 2024 chaudharyrishabh.com</p>

        </div>
    )
}

export default Footer